
import "./App.css";
import Contact from "./components/Contact/Contact";
import { useContext } from "react";
import { themeContext } from "./Context";
function EBOOK() {
  const theme = useContext(themeContext);
  const darkMode = theme.state.darkMode;
  return (
    <div
      className="App"
      style={{
        background: darkMode ? "black" : "#050514",
        color: darkMode ? "white" : "#050514",
      }}
    >
      <Contact /> 
    

    </div>
  );
}

export default EBOOK;
