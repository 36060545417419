import React from "react";
import "./Navbar.css";

const navbar = () => {
  return (
    <div id="nav_">
      <div>
        <div id="nav_" className="n-name" 
        style={{  fontWeight:'900',
        fontSize:32, fontFamily:'Helvetica', 
        textAlign:'center'
        ,
        padding:20,
        color:'#ffffff',
        }}>
        
        <img  id="img_"
        style={{
          width:65,
          marginRight:20,
          marginTop:'-19px',
          float:'left',

        }}
        src='' />
        <p id="texto_nav_">CONVERSÃO DE ASSOCIADO PELO WHATSAPP  </p>
               
        </div>
      </div>

     
    </div>
  );
};

export default navbar;
