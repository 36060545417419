import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import EBOOK from './ebook';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ThemeProvider } from './Context';

ReactDOM.render(
  <ThemeProvider>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}>
        </Route>
        <Route path="/ebook" element={<EBOOK />}></Route>
      </Routes>
    </BrowserRouter>
    
  </ThemeProvider>,
  document.getElementById('root')
);

