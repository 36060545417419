import "./Landing.css";
import { motion } from "framer-motion";


const Landing = () => {
const transition = { duration: 2, type: "spring" };

  return (
    <div className="Intro1" id="Intro1">


<div  id="intro_">
 
   
      </div>

      <div className="i-left">
      <span id="Texto_inicio_atise">atise assist</span>

      <span id="Texto_meio">Somos especialistas em desenvolvimento de ferramentas, técnicas e procedimentos voltados para associações de proteção veicular.  Desenvolvemos um e-book com mais de 20 páginas com o que há de mais novo e eficaz em técnicas de persuasão, gatilhos mentais, provas sociais de forma humanizada quebrando a impressão de interação robótica da maioria das conversas de consultores via WhatsApp. Tá faltando o que pra fazer o download gratuito?
      </span>
      <motion.img
          initial={{ left: "-36%" }}
          whileInView={{ left: "-24%" }}
          transition={transition}
          src=''
          alt=""
        />

        <motion.div
          initial={{ top: "-4%", left: "174%" }}
          whileInView={{ left: "68%" }}
          transition={transition}
          className="floating-div"
        >
       
        </motion.div>

        <motion.div
          initial={{ left: "9rem", top: "18rem" }}
          whileInView={{ left: "0rem" }}
          transition={transition}
          className="floating-div"
        >
         <a id="button__"href="ebook" style={{textAlign:'center !important' }}> 
         <span id="icon_texto"></span><p id="float_text_">DOWNLOAD GRÁTIS DO EBOOK!</p></a>

        </motion.div>

       
        
        </div>
     

    </div>
  );
};

export default Landing;
