import React, {useRef, useState } from "react";
import "./Contact.css";
import emailjs from "@emailjs/browser";
import EBOOK from "../../img/boy.png";
import { motion } from "framer-motion";

  const Contact = () => {
  const transition = { duration: 2, type: "spring" };
  const form = useRef();
  const [done, setDone] = useState(false)
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_n8sn62u",
        "template_1av4s6p",
        form.current,
        "user_KQJz9UxEipqsznwIHHrMc"
      )
      .then(
        (result) => {
          console.log(result.text);
          setDone(true);
          form.reset();
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <div className="contact-form" id="contact">
     <img src={EBOOK} id="book_contato"
        style={{
          marginTop:'-90px',
          marginLeft:'20px',
        }}
        alt="" />
        
      <div className="c-right" id="formulario">
        <form ref={form} onSubmit={sendEmail}>
       
          <input required id="input_envio"type="text" name="nome" className="user"  placeholder="seu nome completo"/>
          <input required id="input_envio" type="number" name="whatsapp" className="user" placeholder="seu numero de whatsapp"/>
          <input required id="input_envio" type="email" name="email" className="user" placeholder="seu melhor email"/>
          <input 
          id="button_donwload_contato"
          type="submit" value=" Download e-book Grátis " />

          <a href="http://atise.website/pdf/estrat%c3%a9gia_de_convers%c3%a3o_pelo_WhatsApp/EBOOK_ATISE.pdf" target="blank_">{done && " CLIQUEI AQUI PARA BAIXAR!! "}</a>
 
     
          <div
            className="blur c-blur1"
            style={{ background: "var(--purple)" }}
          ></div>
           
        </form>
        
      </div>

    </div>
  );
};

export default Contact;
