import "./Intro.css";
import EBOOK from "../../img/boy.png";
import { motion } from "framer-motion";
const Intro = () => {
  const transition = { duration: 2, type: "spring" };


  return (
    <div className="Intro" id="Intro">


<div className="i-right" id="intro_">
 
        <img id="ebook___"src={EBOOK} 
        style={{
          marginTop:'-180px',
          marginLeft:'60px',
        }}
        alt="" />
        <motion.img
          initial={{ left: "-36%" }}
          whileInView={{ left: "-24%" }}
          transition={transition}
          src=''
          alt=""
        />

        <motion.div
          initial={{ top: "-4%", left: "174%" }}
          whileInView={{ left: "68%" }}
          transition={transition}
          className="floating-div"
        >
       
        </motion.div>

        <motion.div
          initial={{ left: "9rem", top: "18rem" }}
          whileInView={{ left: "0rem" }}
          transition={transition}
          className="floating-div"
        >
       <span id="icon_texto"></span><p id="float_text_">abordagem</p>
       <span id="icon_texto"></span><p id="float_text_">quebra de objeções</p>
       <span id="icon_texto"></span><p id="float_text_">prova social</p>
        </motion.div>

        <div className="blur" style={{ background: "rgb(238 210 255)" }}></div>
        <div
          className="blur"
          style={{
            background: "#C1F5FF",
            top: "17rem",
            width: "21rem",
            height: "11rem",
            left: "-9rem",
          }}
        ></div>
      </div>

      <div className="i-left">
        <div className="i-name">

          <span id="Texto_inicio">  
          É sério que você não tem um Copyright eficaz para conversão da sua Proteção Veicular? 
          </span>
         
          <span id="Texto_meio">
          Temos um script de abordagem eficaz para potencializar a conversão pelo 'whatsapp', com técnicas de atração para conversas mais interessantes com gatilhos mentais, quebra de objeções, prova social é muito mais… Baixe agora e converta muito mais!

          </span>
 
        </div>
    
        <a href="ebook"> 
       <button id="button_donwload1"> DOWNLOAD GRÁTIS DO EBOOK!</button></a>
        </div>
     
    </div>
  );
};

export default Intro;
